<template>
  <div class="tile is-ancestor is-parent quote-documents"
    :class="{'quote-documents-embedded p-0': embedded}"
    style="height: 100%;">
    <div class="tile is-parent"
      style="height: 100%;">
      <article class="tile is-child box detail-page-tile"
        style="height: 77vh;">
        <div class="columns">
          <div class="column">
            <p class="title">
              <span class="has-badge-child-offset2">Documents</span>
              <span v-if="documentCount && !embedded"
                class="has-badge-rounded has-badge-info has-badge-large"
                :data-badge="documentCount" />

              <a class="button is-secondary is-pulled-right"
                v-if="!embedded"
                @click="toggleCategoryModal(true)">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Category</span>
              </a>
            </p>
          </div>
        </div>

        <div class="columns is-multiline p-0 m-0 is-align-items-center px-2"
          style="border-radius: 5px;">
          <div class="column is-half p-0 m-0">
            <label class="label py-3 has-text-light">Select Category to Upload</label>
          </div>

          <div class="column is-half pl-2 m-0">
            <div class="select select-dark is-fullwidth">
              <select v-model="selectedCategory">
                <option v-for="category in categories"
                  :value="category"
                  :key="category.quoteAttachmentCategoryId">{{ category.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-full p-0 m-0 mt-0 mb-2 mt-2">
          <file-drop-zone :quote-id="value.quoteId"
            style="height: 100%; "
            :default-size="64"
            :disabled="!selectedCategory"
            :quote-attachment-category-id="selectedCategory?.quoteAttachmentCategoryId"
            @image-uploaded="imageUpdated()"
            additional-classes="quote-attachment-dropzone" />
        </div>

        <div class="is-flex is-justify-content-space-between">
          <a class="button button-dark is-small dark-primary"
            v-if="$user.info.isSystemAdministrator"
            @click="toggleCategoryModal(true)">
            <span class="has-text-primary pr-2">
              <i class="mdi mdi-18px mdi-plus" />
            </span>
            <span>Add/Edit Category</span>
          </a>
        </div>

        <div v-show="isLoading > 0"
          class="columns is-multiline">
          <div class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
        </div>

        <template v-if="$user.info.isSystemAdministrator">
          <quote-attachment-category-modal :active="modalOpen"
            @cancel="toggleCategoryModal(false)"
            @categories-changed="getQuoteAttachmentCategories()" />
        </template>

        <div class="mt-2"
          :style="{'height': $user.info.isSystemAdministrator ? '60%' : '65%'}"
          style="overflow-y: auto; overflow-x: hidden;">
          <div v-show="!isLoading"
            class="mt-3"
            v-for="(category) in categories"
            :key="category.quoteAttachmentCategoryId">
            <quote-attachment-category :category="category"
              :key="category.quoteAttachmentCategoryId"
              :quote-id="value.quoteId"
              :attachments="category.attachments"
              :categories="categories"
              :embedded="embedded"
              @update:attachments="(e)=>handleUpdateAttachments(e)"
              @image-uploaded="getQuoteAttachments()" />
          </div>

        </div>

      </article>
    </div>
  </div>
</template>

<script>
import QuoteService from './QuoteService'
import { EventHubTypes } from '@/enums'
import QuoteAttachmentCategory from './components/QuoteAttachmentCategory.vue'
import QuoteAttachmentCategoryModal from './components/QuoteAttachmentCategoryModal.vue'
import FileDropZone from '@/components/FileDropZone.vue'
import _cloneDeep from 'lodash/cloneDeep'
// import VueDropzone from '@/components/VueDropzone'

export default {
  name: 'QuoteDocuments',
  components: {
    QuoteAttachmentCategory,
    QuoteAttachmentCategoryModal,
    // VueDropzone
    FileDropZone
  },
  props: {
    value: null,
    embedded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCategory: null,
      categories: [],
      attachments: {},
      isLoading: 0,
      modalOpen: false
    }
  },
  computed: {
    documentCount() {
      return Object.values(this.attachments).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
    }
  },
  created() {
    this.getQuoteAttachmentCategories()
    this.getQuoteAttachments()
  },
  mounted() {},
  methods: {
    handleUpdateAttachments(e) {
      const { category, attachment, isSelected } = e
      //console.log('caught update:attachments', category, attachment, isSelected)
      let cloned = _cloneDeep(this.categories)
      for (let c of cloned) {
        if (c.quoteAttachmentCategoryId != category.quoteAttachmentCategoryId) {
          continue
        }

        for (let a of c.attachments) {
          if (a.quoteAttachmentId != attachment.quoteAttachmentId) {
            continue
          }

          a.isSelected = isSelected
          a.orderIndex = attachment.orderIndex
          //console.log('changed')
        }
      }

      this.categories = cloned
    },
    toggleCategoryModal(value) {
      this.modalOpen = value
    },
    getAttachments(quoteAttachmentCategoryId) {
      return Object.values(Object.values(this.attachments[quoteAttachmentCategoryId]?.quoteAttachments ?? {}))
    },
    sortQuoteAttachments() {
      if (this.categories.length == 0 || Object.keys(this.attachments) == 0) {
        return
      }

      for (let category of this.categories) {
        let attachments = this.getAttachments(category.quoteAttachmentCategoryId).sort(function (a, b) {
          return a.orderIndex > b.orderIndex
        })
        for (let attachment of attachments) {
          attachment.isSelected = false
        }
        category.attachments = attachments
      }
    },
    async getQuoteAttachmentCategories() {
      this.isLoading += 1
      var response = await QuoteService.getQuoteAttachmentCategories()
      this.categories = response

      console.log(response)

      this.isLoading -= 1
      this.sortQuoteAttachments()
      this.selectedCategory = this.categories[0] ? this.categories[0] : null
    },
    async getQuoteAttachments() {
      this.isLoading += 1
      var response = await QuoteService.getQuoteAttachments(this.value.quoteId)
      this.attachments = response.data

      let documentCount = Object.values(this.attachments).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
      this.$eventHub.$emit(EventHubTypes.DocumentCountChanged, documentCount)
      this.isLoading -= 1
      this.sortQuoteAttachments()
    },
    imageUpdated() {
      this.$nextTick(() => {
        this.getQuoteAttachments()
      })
    }
  }
}
</script>
