<template>
  <div class="tile is-ancestor is-parent quote-remarks"
    :class="{'quote-remarks-embedded p-0': embedded}"
    style="height: 100%;">

    <div class="tile is-parent"
      style="height: 100%;">

      <article class="tile is-child box detail-page-tile"
        style="height: 100%;">

        <div class="columns">
          <div class="column">
            <p class="title">
              <span class="has-badge-child-offset2">Notes &#38; Remarks </span>
            </p>
          </div>
        </div>

        <div class="columns is-multiline"
          style="max-height: 78vh; overflow-y: auto; overflow-x: hidden;">

          <!---------------------------------------------------- Remarks --------------------------------------------->

          <div class="column is-two-fifths has-text-white is-flex is-align-items-center pb-1">
            Remarks ({{ remarkTotalRows }})
          </div>

          <div class="column is-flex has-text-light p-0 is-align-items-center">
            <div class="is-flex is-align-items-center"
              v-if="Math.ceil(remarkTotalRows / filter.pageSize) > 1">
              <span class="mdi mdi-arrow-left-drop-circle mdi-24px is-clickable"
                @click="filter.pageIndex > 1 ? filter.pageIndex = filter.pageIndex - 1 : null" />
              <span class="mx-2">
                Page {{ filter.pageIndex }} / {{ Math.ceil(remarkTotalRows / filter.pageSize) }}
              </span>
              <span @click="filter.pageIndex < Math.ceil(remarkTotalRows / filter.pageSize) ? filter.pageIndex = filter.pageIndex + 1 : null"
                class="mdi mdi-arrow-right-drop-circle mdi-24px is-clickable" />
            </div>
          </div>

          <div class="column is-one-fifth pb-1">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <a class="button is-small is-primary is-outlined tooltip"
                    data-tooltip="Add Remarks"
                    @click="toggleRemarkModal()">
                    <span class="icon">
                      <i class="mdi mdi-18px mdi-plus" />
                    </span>
                    <span>New</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(remark, index) in pagedRemarks"
            :key="index + 'remark'"
            class=" column is-full note-cell has-text-light has-background-dark">

            <div class="columns">
              <div class="column is-four-fifths p-1">
                <div class=" ml-1">
                  <div v-show="editRemarkRowNumber === -1 || editRemarkRowNumber !== index">{{ remark.remarks }}</div>
                  <textarea v-if="editRemarkRowNumber === index"
                    @blur="handleBlur(index, null)"
                    class="textarea"
                    v-model="remark.remarks"
                    rows="3"
                    v-focus-inserted />
                </div>

                <div class="is-flex is-align-items-center is-size-7 mt-1">
                  <user-profile-icon v-if="remark.firstName || remark.displayName"
                    :is-text-profile="true"
                    :first-name="remark.firstName"
                    :last-name="remark.lastName"
                    :display-name="remark.displayName"
                    :width="20"
                    :height="20"
                    class="pr-1" />
                  <span class="is-italic is-size-7">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </div>

              </div>

              <div class="column">
                <div class="is-flex is-justify-content-flex-end"
                  v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator">
                  <a class="has-text-light is-small is-inverted mr-0"
                    @click="editRemark(index)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px has-text-grey"
                        :class="[editRemarkRowNumber !== index ? 'mdi-pencil-outline' : 'mdi-check']" />
                    </span>
                  </a>
                  <a class="is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi has-text-grey mdi-delete-outline mdi-24px" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full is-flex is-justify-content-center"
            v-if="!pagedRemarks.length">
            <span class="has-text-grey is-size-7">
              No Remarks to show...
            </span>
          </div>

          <!---------------------------------------------------- Printed Remarks --------------------------------------------->

          <div class="column is-two-fifths has-text-white is-flex is-align-items-center pb-1">
            Printed Remarks ({{ printedRemarkTotalRows }})
          </div>
          <div class="column is-flex p-0 has-text-light is-align-items-center">
            <div class="is-flex is-align-items-center "
              v-if="Math.ceil(printedRemarkTotalRows / printedFilter.pageSize) > 1">
              <span class="mdi mdi-arrow-left-drop-circle mdi-24px is-clickable"
                @click="printedFilter.pageIndex > 1 ? printedFilter.pageIndex = printedFilter.pageIndex - 1 : null" />
              <span class="mx-2">
                Page {{ printedFilter.pageIndex }} / {{ Math.ceil(printedRemarkTotalRows / printedFilter.pageSize) }}
              </span>
              <span @click="printedFilter.pageIndex < Math.ceil(printedRemarkTotalRows / printedFilter.pageSize) ? printedFilter.pageIndex = printedFilter.pageIndex + 1 : null"
                class="mdi mdi-arrow-right-drop-circle mdi-24px is-clickable" />
            </div>
          </div>

          <div class="column is-one-fifth pb-1">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <a class="button is-small is-primary is-outlined tooltip"
                    data-tooltip="Add Remarks"
                    @click="toggleRemarkModal()">
                    <span class="icon">
                      <i class="mdi mdi-18px mdi-plus" />
                    </span>
                    <span>New</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(remark, index) in pagedPrintedRemarks"
            :key="index + 'printedRemark'"
            class=" column is-full note-cell has-text-light has-background-dark">

            <div class="columns">
              <div class="column is-four-fifths p-1">
                <div class=" ml-1">
                  <div v-show="editPrintedRemarkRowNumber === -1 || editPrintedRemarkRowNumber !== index">{{ remark.remarks }}</div>
                  <textarea v-if="editPrintedRemarkRowNumber === index"
                    class="textarea"
                    v-model="remark.remarks"
                    @blur="editPrintedRemarkRowNumber === index ? handleBlur(null, index) : null"
                    rows="3"
                    v-focus-inserted />
                </div>

                <div class="is-flex is-align-items-center is-size-7 mt-1">
                  <user-profile-icon v-if="remark.firstName || remark.displayName"
                    :is-text-profile="true"
                    :first-name="remark.firstName"
                    :last-name="remark.lastName"
                    :display-name="remark.displayName"
                    :width="20"
                    :height="20"
                    class="pr-1" />
                  <span class="is-italic is-size-7">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </div>

              </div>

              <div class="column">
                <div class="is-flex is-justify-content-flex-end "
                  v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator">
                  <a class="has-text-light is-small is-inverted"
                    @click="editPrintedRemark(index, editPrintedRemarkRowNumber)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px has-text-grey"
                        :class="[editPrintedRemarkRowNumber === index ? 'mdi-check' : 'mdi-pencil-outline']" />
                    </span>
                  </a>
                  <a class="is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi has-text-grey mdi-delete-outline mdi-24px" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full is-flex is-justify-content-center"
            v-if="!pagedPrintedRemarks.length">
            <span class="has-text-grey is-size-7">
              No Printed Remarks...
            </span>
          </div>

          <!---------------------------------------------------- Other Remarks --------------------------------------------->

          <div class="column is-half has-text-white is-flex is-align-items-center pb-1">
            <div>
              <span>Other Remarks ({{ otherRemarkTotalRows }})</span>
              <div class="column is-flex  has-text-light is-align-items-center p-0"
                v-if="Math.ceil(otherRemarkTotalRows / otherFilter.pageSize) > 1">
                <span class="mdi mdi-arrow-left-drop-circle mdi-24px is-clickable"
                  @click="otherFilter.pageIndex > 1 ? otherFilter.pageIndex = otherFilter.pageIndex - 1 : null" />
                <span class="mx-2">
                  Page {{ otherFilter.pageIndex }} / {{ Math.ceil(otherRemarkTotalRows / otherFilter.pageSize) }}
                </span>
                <span @click="otherFilter.pageIndex < Math.ceil(otherRemarkTotalRows / otherFilter.pageSize) ? otherFilter.pageIndex = otherFilter.pageIndex + 1 : null"
                  class="mdi mdi-arrow-right-drop-circle mdi-24px is-clickable" />
              </div>
            </div>

          </div>

          <div class="column is-half pb-1">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <div class="field">
                    <div class="control is-pulled-right">
                      <div class="is-pulled-right">
                        <div class="select select-dark">
                          <select v-model="otherRemarksIdFilter">
                            <option :value="0">All</option>
                            <option v-for="(propValue, prop) in otherRemarkTypes"
                              :key="propValue"
                              :value="propValue">{{ startCase(prop) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(remark, index) in pagedOtherRemarks"
            :key="index + 'otherRemark'"
            class=" column is-full note-cell has-text-light has-background-dark">
            <div class="columns">
              <div class="column is-four-fifths p-1">
                <div class=" ml-1">
                  <div>{{ remark.remarks }}</div>
                </div>
                <div class="is-flex is-align-items-center is-size-7 mt-1">
                  <user-profile-icon v-if="remark.firstName || remark.displayName"
                    :is-text-profile="true"
                    :first-name="remark.firstName"
                    :last-name="remark.lastName"
                    :display-name="remark.displayName"
                    :width="20"
                    :height="20"
                    class="pr-1" />
                  <span class="is-italic is-size-7">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </div>
              </div>
              <div class="column">
                <div class="is-flex is-justify-content-flex-end"
                  v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator">
                  <a class="is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi has-text-grey mdi-delete-outline mdi-24px" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full is-flex is-justify-content-center"
            v-if="!pagedOtherRemarks.length">
            <span class="has-text-grey is-size-7">
              No Other Remarks...
            </span>
          </div>

          <!-------------------------------------------      Notes   --------------------------------------->

          <div class="column is-half has-text-white is-flex is-align-items-center pb-0">
            <span>
              Incident Notes
            </span>
          </div>
          <div class="column is-half is-flex pb-0">
            <div class="field has-addons">
              <v-date-picker v-model="selectedIncidentNotesDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-right">
                      <input type="text"
                        class="input input-dark"
                        placeholder="Incident Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-right ">
                        <i class="mdi mdi-calendar mdi-18px has-text-primary" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>

          <div class="column is-full pt-0">
            <div class="field">
              <div class="control">
                <textarea class="textarea textarea-dark"
                  v-model="incidentNotesText"
                  placeholder="" />
              </div>
            </div>

          </div>

        </div>

      </article>
    </div>
    <remark-modal v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Quote Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { RemarkModal } from '@/components/BulmaModal'
import { RemarkTypes } from '@/enums'
import { RemarkColumns } from './columns'
// import BulmaTable from '@/components/BulmaTable'
import RemarkService from '@/services/RemarkService'
import { FocusInserted } from '@/components/directives'
import UserProfileIcon from '@/components/UserProfileIcon/UserProfileIcon'
import { RemarkModel } from '@/classes/viewmodels'
import _startCase from 'lodash.startcase'

export default {
  name: 'QuoteRemarks',
  directives: {
    FocusInserted
  },
  components: {
    RemarkModal,
    UserProfileIcon
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    embedded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      isRemarkModalActive: false,
      incidentNotesText: '',
      // incidentNotesDate: null,
      selectedIncidentNotesDate: null,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      printedFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      otherFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      dueInFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 4
      },
      dueOutFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 4
      },
      editRemarkRowNumber: -1,
      editPrintedRemarkRowNumber: -1,
      editDueInNoteRowNumber: -1,
      editDueOutNoteRowNumber: -1,
      otherRemarkTypes: {
        //subset or RemarkTypes.js
        ORMSystemRemark: 19,
        PNETSystemRemark: 20,
        OtherSystemRemark: 21,
        ORMMessageRemark: 22,
        PNETMessageRemark: 23
      },
      otherRemarksIdFilter: 0,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    columns() {
      return RemarkColumns
    },
    remarkTypes() {
      return RemarkTypes
    },
    remarkTotalRows() {
      return this.innerValue.remarks.filter((r) => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted).length
    },
    printedRemarkTotalRows() {
      return this.innerValue.printedRemarks.filter((r) => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted).length
    },
    otherRemarkTotalRows() {
      return this.innerValue.otherRemarks.filter((r) => !r.isDeleted).length
    },
    dueInNotesTotalRows() {
      return this.innerValue.dueInNotes.filter((r) => !r.isDeleted).length
    },
    dueOutNotesTotalRows() {
      return this.innerValue.dueOutNotes.filter((r) => !r.isDeleted).length
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    },
    pagedPrintedRemarks() {
      return this.activePrintedRemarks.slice(
        (this.printedFilter.pageIndex - 1) * this.printedFilter.pageSize,
        this.printedFilter.pageIndex * this.printedFilter.pageSize
      )
    },
    pagedOtherRemarks() {
      return this.activeOtherRemarks.slice((this.otherFilter.pageIndex - 1) * this.otherFilter.pageSize, this.otherFilter.pageIndex * this.otherFilter.pageSize)
    },
    pagedDueInNotes() {
      return this.activeDueInNotes.slice((this.dueInFilter.pageIndex - 1) * this.dueInFilter.pageSize, this.dueInFilter.pageIndex * this.dueInFilter.pageSize)
    },
    pagedDueOutNotes() {
      return this.activeDueOutNotes.slice(
        (this.dueOutFilter.pageIndex - 1) * this.dueOutFilter.pageSize,
        this.dueOutFilter.pageIndex * this.dueOutFilter.pageSize
      )
    },
    activeRemarks() {
      return this.innerValue.remarks.filter((r) => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter((r) => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted)
    },
    activeOtherRemarks() {
      if (!this.otherRemarksIdFilter) {
        return this.innerValue.otherRemarks.filter((r) => !r.isDeleted)
      } else {
        return this.innerValue.otherRemarks.filter((r) => !r.isDeleted && r.remarkTypeId === this.otherRemarksIdFilter)
      }
    },
    activeDueInNotes() {
      return this.innerValue.dueInNotes.filter((r) => r.remarkTypeId === RemarkTypes.DueInNote && !r.isDeleted)
    },
    activeDueOutNotes() {
      return this.innerValue.dueOutNotes.filter((r) => r.remarkTypeId === RemarkTypes.DueOutNote && !r.isDeleted)
    },
    incidentNotesRemark() {
      return this.innerValue.incidentNotes
    },
    incidentNotesData() {
      // return `${this.incidentNotesText}${this.incidentNotesDate}`
      return `${this.incidentNotesText}${this.selectedIncidentNotesDate}`
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = newVal
      },
      deep: true
    },
    incidentNotesData: {
      handler: async function (newVal, oldVal) {
        if (newVal) {
          if (this.innerValue.incidentNotes === undefined || !this.innerValue.incidentNotes) {
            this.innerValue.incidentNotes = await RemarkService.getNewEntity()
            this.innerValue.incidentNotes.remarkTypeId = RemarkTypes.INCNOT
            this.innerValue.incidentNotes.assetId = this.innerValue.quoteId
          }
          this.innerValue.incidentNotes.remarks = this.incidentNotesText
          // this.innerValue.incidentNotes.remarkDateTime = this.incidentNotesDate.toISOString().split('.')[0] + 'Z'
          this.innerValue.incidentNotes.remarkDateTime = this.selectedIncidentNotesDate.toISOString().split('.')[0] + 'Z'
        }
      }
    }
  },
  created() {
    this.innerValue = this.value
    if (this.incidentNotesRemark) {
      this.incidentNotesText = this.incidentNotesRemark.remarks
      if (this.incidentNotesRemark.remarkDateTime) {
        // this.incidentNotesDate = new Date(`${this.incidentNotesRemark.remarkDateTime}`)
        this.selectedIncidentNotesDate = new Date(`${this.incidentNotesRemark.remarkDateTime}`)
      }
    }
  },
  mounted() {},
  methods: {
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(
          this.innerValue.quoteId,
          RemarkTypes.Remark,
          remark,
          this.$userInfo.firstName,
          this.$userInfo.lastName,
          this.$userInfo.displayName
        )
        this.innerValue.remarks.splice(this.innerValue.remarks.length, 1, newRemark)
        this.innerValue.remarks.sort(function (a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(
          this.innerValue.quoteId,
          RemarkTypes.PrintedRemark,
          printedRemark,
          this.$userInfo.firstName,
          this.$userInfo.lastName,
          this.$userInfo.displayName
        )
        this.innerValue.printedRemarks.splice(this.innerValue.printedRemarks.length, 1, newPrintedRemark)
        this.innerValue.printedRemarks.sort(function (a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    deleteRemark(index, remarkId, remarkType) {
      let deletedRemark
      console.log('remarkType: ', remarkType)
      if (remarkType === RemarkTypes.Remark) {
        deletedRemark = this.activeRemarks.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.remarks.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.remarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.PrintedRemark) {
        deletedRemark = this.activePrintedRemarks.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.printedRemarks.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.printedRemarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.DueInNote) {
        deletedRemark = this.activeDueInNotes.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.dueInNotes.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.dueInNotes.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.DueOutNote) {
        deletedRemark = this.activeDueOutNotes.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.dueOutNotes.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.dueOutNotes.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else {
        deletedRemark = this.innerValue.otherRemarks.find((r) => r.remarkId == remarkId)
        deletedRemark.isDeleted = true
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    onPrintedRemarksPageChange(pageIndex) {
      this.printedFilter.pageIndex = pageIndex
    },
    onOtherRemarksPageChange(pageIndex) {
      this.otherFilter.pageIndex = pageIndex
    },
    onDueInNotePageChange(pageIndex) {
      this.dueInFilter.pageIndex = pageIndex
    },
    onDueOutNotePageChange(pageIndex) {
      this.dueOutFilter.pageIndex = pageIndex
    },
    sort(name, order) {},
    onSelectIncidentNotesDate(date, dateNow) {
      // this.innerValue.incidentNotes.remarkDateTime = dateNow.toISOString().split('.')[0] + 'Z'
    },
    editRemark(row) {
      if (this.editRemarkRowNumber === -1) {
        this.editRemarkRowNumber = row
        this.editPrintedRemarkRowNumber = -1
      } else {
        this.editRemarkRowNumber = -1
      }
    },
    editPrintedRemark(row) {
      if (this.editPrintedRemarkRowNumber === -1) {
        this.editPrintedRemarkRowNumber = row
        this.editRemarkRowNumber = -1
      } else {
        this.editPrintedRemarkRowNumber = -1
      }
    },
    editDueInNote(row) {
      this.editDueInNoteRowNumber !== row ? (this.editDueInNoteRowNumber = row) : (this.editDueInNoteRowNumber = -1)
      this.editDueOutNoteRowNumber = -1
    },
    editDueOutNote(row) {
      this.editDueOutNoteRowNumber !== row ? (this.editDueOutNoteRowNumber = row) : (this.editDueOutNoteRowNumber = -1)
      this.editDueInNoteRowNumber = -1
    },
    camelCaseToStartCase(str) {
      return str.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1').replace(/^./, (s) => s.toUpperCase())
    },
    startCase(str) {
      return _startCase(str)
    },
    async handleBlur(remark, printedRemark) {
      // this is to fix a race condition where the textarea is blurred and the click event is fired at the same time
      await new Promise((resolve) => setTimeout(resolve, 100))
      if (remark === this.editRemarkRowNumber) {
        this.editRemarkRowNumber = -1
      } else if (printedRemark === this.editPrintedRemarkRowNumber) {
        this.editPrintedRemarkRowNumber = -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.note-cell {
  border: 2px solid #42484e;
  width: 100%;
  border-radius: 5px;
}
</style>