<template>
  <div class="tile is-ancestor is-parent quote-documents"
    style="height: 100%; "
    :class="{'quote-documents-embedded p-0': embedded}">
    <div class="tile is-parent"
      style="height: 100%; ">
      <article class="tile is-child box detail-page-tile has-text-white"
        style="height: 100%;">

        <div class="columns">
          <div class="column pb-1">
            <p class="title">
              <span class="has-badge-child-offset2">Status</span>
            </p>
          </div>
        </div>
        <!-- TODO: try to fix this later, make it dynamic -->
        <div class="columns is-multiline"
          style="max-height: 79vh; overflow-y: scroll;">

          <div class="column is-full">
            Quoting Stage
          </div>

          <div class="column is-full"
            style="position: relative;">
            <div v-for="(quoteStage, index) in quoteStages"
              :key="quoteStage.stage"
              class="columns">
              <div class="column is-two-fifths is-flex is-justify-content-center"
                style="position: relative;">
                <div class="is-flex is-align-items-center is-justify-content-center designed-button has-text-weight-bold
                  is-clickable is-responsive"
                  @click="setCurrentJobStage(quoteStage.stage)"
                  :class="{'has-background-grey-lighter has-text-dark': quoteStage?.sortKey > currentJobStage?.sortKey || !quoteStage?.sortKey, 'has-background-primary': quoteStage?.sortKey <= currentJobStage?.sortKey}">
                  {{ index + 1 }}
                </div>
                <div v-if="index !== 0"
                  class="progress-bar-vertical-small "
                  :class="{'has-background-grey-lighter': quoteStage?.sortKey > currentJobStage?.sortKey || !quoteStage?.sortKey, 'has-background-primary': quoteStage?.sortKey <= currentJobStage?.sortKey}"
                  style="position: absolute;" />
              </div>
              <div class="column is-three-fifths">
                <span class="text-elipsis">
                  {{ quoteStage.description }}
                </span>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <div class="mb-4 "
              style="border-top: 2px solid gray;" />
            <span>
              Job Stage
            </span>
          </div>

          <div class="column is-full"
            style="position: relative;">
            <div v-for="(quoteStage, index) in jobStagesArr"
              :key="quoteStage.stage"
              class="columns">
              <div class="column is-two-fifths is-flex is-justify-content-center"
                style="position: relative;">
                <div class="is-flex is-align-items-center is-justify-content-center designed-button has-text-weight-bold
                 is-clickable is-responsive"
                  @click="setCurrentJobStage(quoteStage.stage)"
                  :class="{'has-background-grey-lighter has-text-dark': quoteStage?.sortKey > currentJobStage?.sortKey ||!quoteStage?.sortKey, 'has-background-primary': quoteStage?.sortKey <= currentJobStage?.sortKey}">
                  {{ index + quoteStages.length + 1 }}
                </div>
                <div v-if="index !== 0"
                  class="progress-bar-vertical-small "
                  :class="{'has-background-grey-lighter': quoteStage?.sortKey > currentJobStage?.sortKey || !quoteStage?.sortKey, 'has-background-primary': quoteStage?.sortKey <= currentJobStage?.sortKey}"
                  style="position: absolute;" />
              </div>
              <div class="column is-three-fifths">
                <span class="text-elipsis">
                  {{ quoteStage.description }}
                </span>
              </div>
            </div>
          </div>
        </div>

      </article>
    </div>
  </div>
</template>

<script>
import { JobStageTypes } from '@/enums'

export default {
  name: 'QuoteStatus',
  components: {},
  props: {
    jobStages: {
      type: Array,
      default: () => []
    },
    value: null,
    embedded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCategory: null,
      categories: [],
      attachments: {},
      isLoading: 0,
      modalOpen: false
    }
  },
  computed: {
    currentJobStage() {
      return this.jobStages.find((stage) => stage.stage === this.value.jobStage)
    },

    quoteStageIds() {
      return [
        JobStageTypes.ToBeQuoted,
        JobStageTypes.QuoteToBeChecked,
        JobStageTypes.AuthorityPending,
        JobStageTypes.FollowUpRequired,
        JobStageTypes.Authorised
      ]
    },
    quoteStages() {
      return this.jobStages.filter((stage) => this.quoteStageIds.includes(stage.stage) && !!stage?.sortKey).sort((a, b) => a?.sortKey - b?.sortKey)
    },
    jobStagesArr() {
      return this.jobStages
        .filter((stage) => !this.quoteStageIds.includes(stage.stage) && stage.stage !== 0 && !!stage?.sortKey)
        .sort((a, b) => a?.sortKey - b?.sortKey)
    }
  },
  methods: {
    setCurrentJobStage(stage) {
      if (!this.value.readOnly) {
        this.value.jobStage = stage
      } else {
        this.$toast.open({
          message: 'Quote is Locked',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.designed-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: 2;

  &:hover {
    scale: 1.1;
    transition: all 0.1s ease-in-out;
    background-color: #3291ef !important;
    color: #fff !important;
  }
}
.progress-bar-vertical-small {
  position: absolute;
  width: 2px;
  height: 80%;
  left: 50%;
  top: -50%;
}

.text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
</style>
