<template>
  <div v-if="companyItemTypes">
    <div class="label-text has-text-primary mb-2">
      <span class="text">Cost Summary</span>
    </div>

    <div class="tabs is-boxed is-small mb-1">
      <ul class="dark">
        <li v-for="suppQuotes in suppQuoteTotals"
          :key=" suppQuotes.subQuoteNo"
          :class="{ 'is-active' : selectedTab === suppQuotes.subQuoteNo}">
          <a @click="selectedTab = suppQuotes.subQuoteNo">
            <span> {{ getSummaryTitle(suppQuotes) }}</span>
          </a>
        </li>
        <li v-if="suppQuoteTotals.length > 1"
          :class="{ 'is-active' : selectedTab === null}">
          <a @click="selectedTab = null">
            <span>Summary</span>
          </a>
        </li>
      </ul>
    </div>

    <table class="table is-narrow is-fullwidth cost-summary">
      <tbody>
        <tr class="no-border"
          v-for="(item) in summaryData.items"
          :key="item.title">
          <td>
            {{ item.title }}
          </td>
          <td class="has-text-right">{{ $filters.formatCurrency(item.value, $userInfo.locale) }}</td>
        </tr>
        <tr class="top-border mt-2">
          <td>
            <span class="text has-text-weight-bold">Sub Total</span>
          </td>
          <td class="has-text-right">
            <span class="text has-text-weight-bold">{{ $filters.formatCurrency(summaryData.totals.totalExGst, $userInfo.locale) }}</span>
          </td>
        </tr>
        <tr class="no-border">
          <td>
            <span class="text has-text-weight-bold">GST</span>
          </td>
          <td class="has-text-right">
            <span class="text has-text-weight-bold">{{ $filters.formatCurrency(summaryData.totals.totalIncGst - summaryData.totals.totalExGst, $userInfo.locale) }}</span>
          </td>
        </tr>
        <tr class="no-border">
          <td>
            <span class="text has-text-weight-bold">Total</span>
          </td>
          <td class="has-text-right">
            <span class="text has-text-weight-bold">{{ $filters.formatCurrency(summaryData.totals.totalIncGst, $userInfo.locale) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import QuoteService from './QuoteService.js'
import StoreMixin from './storeMixin'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'QuoteCostSummary',
  components: {},
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    value: null,
    embedded: {
      type: Boolean,
      default: false
    },
    insurer: null
  },
  data() {
    return {
      quoters: [],
      suppQuoteTotals: [],
      selectedTab: null,
      summaryData: null,
      summaryDataEmptyObj: {
        items: {
          rrTotal: {
            value: 0,
            title: 'Remove & Refit'
          },
          repairTotal: {
            value: 0,
            title: 'Repair'
          },
          paintTotal: {
            value: 0,
            title: 'Paint'
          },
          mechTotal: {
            value: 0,
            title: 'Mechanical'
          },
          partTotal: {
            value: 0,
            title: 'Parts'
          },
          sublTotal: {
            value: 0,
            title: 'Sublets'
          },
          miscTotal: {
            value: 0,
            title: 'Miscellaneous'
          },
          opgTotal: {
            value: 0,
            title: 'OPG'
          }
        },
        totals: {
          totalIncGst: 0,
          totalExGst: 0
        }
      }
    }
  },
  computed: {
    filteredQuoters() {
      return this.quoters.filter((option) => {
        return option.toString().toLowerCase().indexOf(this.value.quoter.toLowerCase()) >= 0
      })
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    totalOtherLabour() {
      return this.value.others.map((other) => other.dollarValue).reduce((acc, val) => acc + val, 0)
    },
    assessmentHistoryToggleDisable() {
      if (this.value.quoteAssessments.length > 0) {
        return true
      }
      if (this.value.invoices.length > 0) {
        return true
      }
      if (this.value.orm && this.value.orm.messageNo != '0') {
        return true
      }
      if (this.value.subQuoteNos.length > 1) {
        return true
      }
      return false
    }
  },
  watch: {
    suppQuoteTotals: {
      handler: function (value) {
        this.calculateTotalValues()
      },
      deep: true
    },
    selectedTab: {
      handler: function (value) {
        this.calculateTotalValues()
      }
    },
    value: {
      handler: function (value) {
        this.calculateTotalValues()
      },
      deep: true
    }
  },
  created() {
    this.getQuoters()
    this.getSuppQuoteTotals()
    this.calculateTotalValues()
  },
  methods: {
    getSummaryTitle(suppQuotes) {
      if (suppQuotes.subQuoteNo === 0) {
        if (this.suppQuoteTotals.length > 1) {
          return 'Main'
        }
        return 'Summary'
      }
      return 'Add-' + suppQuotes.subQuoteNo
    },
    async getQuoters() {
      this.quoters = await QuoteService.getQuoters()
    },
    async getSuppQuoteTotals() {
      this.suppQuoteTotals = await QuoteService.getSupplementaryQuoteTotals(this.value.quoteId)
      this.selectedTab = this.suppQuoteTotals.length === 1 ? 0 : null
    },
    getDisplayName(itemCategoryType) {
      if (this.companyItemTypes) {
        const itemType = this.companyItemTypes.find((i) => i.itemCategoryType === itemCategoryType)
        if (itemType) {
          return itemType.displayName
        }
      }
      return itemCategoryType
    },
    driveableOnChange(event) {
      this.value.driveable = event.target.checked
    },
    toggleActive(active) {
      this.value.deleted = !active
    },
    inScopeChange(event) {
      this.value.inScope = event.target.checked
    },
    assessmentHistoryChange(event) {
      this.value.isAssessmentEnabled = event.target.checked
    },
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
        this.value.readOnly = !this.value.readOnly
        this.value.readOnlyStatuses.splice(this.value.subQuoteNo, 1, this.value.readOnly)
      }
    },
    calculateTotalValues() {
      const clonedSummaryData = cloneDeep(this.summaryDataEmptyObj)
      const keys = Object.keys(clonedSummaryData.items)
      const totalKeys = Object.keys(clonedSummaryData.totals)

      const currentQuoteSubQuoteNo = this.value.subQuoteNo

      // add up values for the main quote from the source as it will update when you add parts, etc.

      // the quote that is selected
      if (this.selectedTab === null || this.selectedTab === currentQuoteSubQuoteNo) {
        keys.forEach((key) => (clonedSummaryData.items[key].value += this.value[key]))
        totalKeys.forEach((key) => (clonedSummaryData.totals[key] += this.value[key]))
      }

      /// adding up additional quotes and the main quote if its not the selected quote
      if (this.suppQuoteTotals.length > 0) {
        this.suppQuoteTotals.forEach((suppQuote) => {
          if ((this.selectedTab === suppQuote.subQuoteNo || this.selectedTab === null) && suppQuote.subQuoteNo !== currentQuoteSubQuoteNo) {
            keys.forEach((key) => (clonedSummaryData.items[key].value += suppQuote[key]))
            totalKeys.forEach((key) => (clonedSummaryData.totals[key] += suppQuote[key]))
          }
        })
      }

      this.summaryData = clonedSummaryData
    }
  }
}
</script>

<style lang="scss" scoped>
th.is-subtotal {
  width: 7em;
}
.cost-summary {
  background: transparent;
  color: white;
  border: none;
}

table {
  &.is-narrow {
    height: 1rem;
  }
  tr.no-border td {
    border: none;
  }
  tr.top-border {
    td {
      border: none;
      border-top: solid 1px rgba(255, 255, 255, 0.2);
      vertical-align: bottom;
    }
    height: 2.5rem;
  }
}
</style>
