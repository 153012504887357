<template>
  <div class="tile is-ancestor is-parent quote-documents"
    style="height: 100%; "
    :class="{'quote-documents-embedded p-0': embedded}">
    <div class="tile is-parent"
      style="height: 100%; ">
      <article class="tile is-child box detail-page-tile has-text-white"
        style="height: 79vh;">

        <div class="columns">
          <div class="column pb-1">
            <p class="title">
              <span class="has-badge-child-offset2">Summary</span>
            </p>
          </div>
        </div>

        <div class="columns is-multiline is-align-items-center mb-0">
          <div class="column is-half is-flex is-align-items-center is-align-content-center">
            <span class="mr-2">Quote Active</span>
          </div>

          <div class="column is-half">
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  :disabled="value.isNew || readOnlyView || !$user.hasDelete($route.meta.id)"
                  @change="toggleActive()"
                  :checked="!value.deleted">
                <div class="state p-primary">
                  <label>{{ !value.deleted ? "Yes" : "No" }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half py-0">Estimator</div>
          <div class="column is-half py-0">
            <div class="field">
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static is-capitalized input-dark"
                  :value="value.quoter"
                  readonly>
              </div>
              <div v-else
                class="control">
                <bulma-autocomplete placeholder="Quoter"
                  class="autocomplete-dark"
                  v-model="value.quoter"
                  :data="filteredQuoters"
                  :keep-first="true"
                  :loading="false"
                  :theme="`dark`" />
              </div>
            </div>
          </div>

          <div v-if="$company.setting.driveable"
            class="column is-half pb-0">Driveable</div>
          <div v-if="$company.setting.driveable"
            class="column is-half pb-0">
            <!-- toggle buttons -->
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  @change="driveableOnChange"
                  :checked="!!value.driveable">
                <div class="state p-primary">
                  <label>{{ value.driveable ? "Yes" : "No" }}</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!insurer.inScope"
            class="column is-half pb-0">In Scope</div>
          <div v-if="!insurer.inScope"
            class="column is-half pb-0">
            <!-- toggle buttons -->
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  @change="inScopeChange"
                  :checked="!!value.inScope">
                <div class="state p-primary">
                  <label>{{ value.inScope ? "Yes" : "No" }}</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="$userInfo.isSupportUser"
            class="column is-half">Enable Assessment History</div>
          <div v-if="$company.setting.driveable"
            class="column is-half">
            <!-- toggle buttons -->
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  @change="assessmentHistoryChange"
                  :disabled="assessmentHistoryToggleDisable"
                  :checked="value.isAssessmentEnabled">
                <div class="state p-primary">
                  <label>{{ value.isAssessmentEnabled ? "Yes" : "No" }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <quote-cost-summary v-if="!value.isAssessmentEnabled"
              v-model="value" />
            <quote-detail-assessment-summary v-else
              v-model="value"
              :is-side="true" />
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import BulmaAutocomplete from '../../components/BulmaAutocomplete/BulmaAutocomplete.vue'
import QuoteService2 from './QuoteService.js'
import QuoteCostSummary from './QuoteCostSummary.vue'
import QuoteDetailAssessmentSummary from './QuoteDetailAssessmentSummary.vue'

export default {
  name: 'QuoteSummary',
  components: { BulmaAutocomplete, QuoteCostSummary, QuoteDetailAssessmentSummary },
  props: {
    jobStages: {
      type: Array,
      default: () => []
    },
    value: null,
    embedded: {
      type: Boolean,
      default: false
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    insurer: null
  },
  data() {
    return {
      quoters: [],
      suppQuoteTotals: [],
      selectedTab: null,
      summaryData: null,
      summaryDataEmptyObj: {
        items: {
          rrTotal: {
            value: 0,
            title: 'Remove & Refit'
          },
          repairTotal: {
            value: 0,
            title: 'Repair'
          },
          paintTotal: {
            value: 0,
            title: 'Paint'
          },
          mechTotal: {
            value: 0,
            title: 'Mechanical'
          },
          partTotal: {
            value: 0,
            title: 'Parts'
          },
          sublTotal: {
            value: 0,
            title: 'Sublets'
          },
          miscTotal: {
            value: 0,
            title: 'Miscellaneous'
          },
          opgTotal: {
            value: 0,
            title: 'OPG'
          }
        },
        totals: {
          totalIncGst: 0,
          totalExGst: 0
        }
      }
    }
  },
  computed: {
    filteredQuoters() {
      return this.quoters.filter((option) => {
        return option.toString().toLowerCase().indexOf(this.value.quoter.toLowerCase()) >= 0
      })
    },
    totalOtherLabour() {
      return this.value.others.map((other) => other.dollarValue).reduce((acc, val) => acc + val, 0)
    },
    assessmentHistoryToggleDisable() {
      if (!this.value.quoteAssessments) {
        return false
      }
      if (this.value.quoteAssessments.length > 0) {
        return true
      }
      if (this.value.invoices.length > 0) {
        return true
      }
      if (this.value.orm && this.value.orm.messageNo != '0') {
        return true
      }
      if (this.value.subQuoteNos.length > 1) {
        return true
      }
      return false
    }
  },
  watch: {
    suppQuoteTotals: {
      handler: function (value) {
        this.calculateTotalValues()
      },
      deep: true
    },
    selectedTab: {
      handler: function (value) {
        this.calculateTotalValues()
      }
    },
    value: {
      handler: function (value) {
        this.calculateTotalValues()
      },
      deep: true
    }
  },
  created() {
    this.getQuoters()
    this.getSuppQuoteTotals()

    this.calculateTotalValues()
  },
  methods: {
    async getQuoters() {
      this.quoters = await QuoteService2.getQuoters()
    },
    async getSuppQuoteTotals() {
      this.suppQuoteTotals = await QuoteService2.getSupplementaryQuoteTotals(this.value.quoteId)
    },
    driveableOnChange(event) {
      this.value.driveable = event.target.checked
    },
    toggleActive() {
      this.value.deleted = !this.value.deleted
    },
    inScopeChange(event) {
      this.value.inScope = event.target.checked
    },
    assessmentHistoryChange(event) {
      this.value.isAssessmentEnabled = event.target.checked
    },
    calculateTotalValues() {
      const clonedSummaryData = cloneDeep(this.summaryDataEmptyObj)
      const keys = Object.keys(clonedSummaryData.items)
      const totalKeys = Object.keys(clonedSummaryData.totals)

      const currentQuoteSubQuoteNo = this.value.subQuoteNo

      // add up values for the main quote from the source as it will update when you add parts, etc.

      // the quote that is selected
      if (this.selectedTab === null || this.selectedTab === currentQuoteSubQuoteNo) {
        keys.forEach((key) => (clonedSummaryData.items[key].value += this.value[key]))
        totalKeys.forEach((key) => (clonedSummaryData.totals[key] += this.value[key]))
      }

      /// adding up additional quotes and the main quote if its not the selected quote
      if (this.suppQuoteTotals.length > 0) {
        this.suppQuoteTotals.forEach((suppQuote) => {
          if ((this.selectedTab === suppQuote.subQuoteNo || this.selectedTab === null) && suppQuote.subQuoteNo !== currentQuoteSubQuoteNo) {
            keys.forEach((key) => (clonedSummaryData.items[key].value += suppQuote[key]))
            totalKeys.forEach((key) => (clonedSummaryData.totals[key] += suppQuote[key]))
          }
        })
      }

      this.summaryData = clonedSummaryData
    }
  }
}
</script>