<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="text-title" />
            <span>Validation Errors</span>
          </p>
        </header>
        <section class="modal-card-body">
          <slot name="text-content" />
          <span>
            There are validation Error(s) on this screen, this may prevent you
            from saving the quote when making changes on other screens.
          </span>

        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-warning tooltip"
            @click="continueNext()"
            data-tooltip="Continue to next page"
            :disabled="skipDisabled">Continue</button>
          <button class="button tooltip is-tooltip-topright"
            @click="close()"
            data-tooltip="Don't continue">Fix Errors</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from './BaseModalEx'

export default {
  name: 'QuoteSaveErrorModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    skipDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    continueNext() {
      this.$emit('continueNext')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
