import { required, requiredIf, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { guidValidator, abnValidator } from '@/components/validators'
import { DateTime } from 'luxon'
// import { mapGetters } from 'vuex'
import store from '@/store'

const dateValidator = function (value) {
  // console.log(value)
  if (value && value !== '__/__/____') {
    const date = DateTime.fromISO(value)
    return date.isValid
  } else {
    return value !== undefined
  }
}

const customMinLength = (value, vehicle) => {
  if (vehicle && vehicle.bodyId && vehicle.bodyId.toUpperCase() === 'O') {
    return true
  } else if (value !== null || value != undefined) {
    return value.length >= 7 || value.length === 0
  }
  return true
}

const getStoreVehicle = () => {
  const vehicle = store.getters['quotes/quoteVehicle']
  // console.log(vehicle)
  // return vehicle ? vehicle.customer.key : null
  return vehicle
}

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  // computed: {
  //   ...mapGetters('quotes', {
  //     validationVehicle: 'quoteVehicle'
  //   })
  // },
  validations: {
    detailGroup: ['entity.quoteId',
    'entity.vehicleId',
    'entity.insurerId',
    'entity.quoter',
    'entity.authorisedDate'],
    // authorisedDate: {
    //   validDate: function(value) {
    //     if (value === '' || value === '__/__/____') {
    //       return true
    //     } else {
    //       const locale = store.getters['userInfo/info'].locale
    //       console.log(value, locale, getLocaleDateFormat(locale))
    //       const date = DateTime.local(value) // DateTime.fromFormat(value, getLocaleDateFormat(locale), { locale: locale })
    //       // console.log(date.isValid || value === '', value, date)
    //       return date.isValid // || value === '' || value === '__/__/____'
    //     }
    //   }
    // },
    vehicleCustomerGroup: ['entity.customerId'],
    vehicleCustomerIsActiveGroup: ['quoteCustomer.active', 'quoteVehicle.isActive'],
    quoteHeaderGroup: [
      'entity.quoteId',
      'entity.vehicleId',
      'entity.insurerId',
      'vehicle.rego',
      'vehicle.model',
      'vehicle.bodyId',
      'vehicle.bodyId2',
      'vehicle.paintGroup',
      'customer.lastname',
      'customer.firstname',
      'customer.emails',
      'insurer.name'
    ],
    ormHeaderGroup: [
      'vehicle.bodyId2',
      'vehicle.buildMonth',
      'vehicle.buildYear',
      'vehicle.colour',
      'vehicle.transmissionType',
      'entity.odometer',
      'entity.claimNo',
      'entity.damageSeverity'
    ],
    ormSummaryGroup: [
      'entity.jobStart',
      'entity.jobEnd',
      'entity.quoter'
    ],
    estimateJobStartEnd: [
      'entity.jobStart',
      'entity.jobEnd'
    ],
    quotingGroup:[
       'entity.parts',
       'entity.labours',
       'entity.miscs',
       'entity.sublets'
    ],
    audaNetHeaderGroup: ['entity.claimNo'],
    entity: {
      quoteId: {
        required,
        validGuid: guidValidator
      },
      quoter: {
        required
      },
      vehicleId: {
        required,
        validGuid: guidValidator
      },
      customerId: {
        required,
        validGuid: guidValidator,
        sameAsVehicle: (value) => {
          // if (this.validationVehicle === undefined || this.validationVehicle === null) {
          //   return false
          // } else {
          //   return this.validationVehicle.customerId === this.entity.customerId
          // }
          // return this.validationVehicle && this.validationVehicle.customerId === this.entity.customerId
          const vehicle = getStoreVehicle()
          return vehicle && vehicle.customer ? value === vehicle.customer.key : true
        }
      },
      insurerId: {
        required,
        validGuid: guidValidator
      },
      authorisedDate: {
        validDate: dateValidator
      },
      labours: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            }),
            validItemDesc: function(value, model) {
              /// cant be just blank lines
              return value.trim() !== ''
            }
          }
        }
      },
      others: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            }),
            validItemDesc: function(value, model) {
              /// cant be just blank lines
              return value.trim() !== ''
            }
          }
        }
      },
      parts: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            }),
            validItemDesc: function(value, model) {
              /// cant be just blank lines
              return value.trim() !== ''
            }
          }
        }
      },
      opgs: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          validItemDesc: function(value, model) {
            /// cant be just blank lines
            return value.trim() !== ''
          }
        }
      },
      miscs: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            }),
            validItemDesc: function(value, model) {
              /// cant be just blank lines
              return value.trim() !== ''
            }
          }
        }
      },
      sublets: {
        $each: {
          itemNo: {
            required: requiredIf(function (model) {
              return !model.deleted
            })
          },
          itemDesc: {
            required: requiredIf(function (model) {
              return !model.deleted
            }),
            validItemDesc: function(value, model) {
              /// cant be just blank lines
              return value.trim() !== ''
            }
          }
        }
      },
      invoices: {
        $each: {
          assetId: {
            required,
            validGuid: guidValidator
          }
        }
      },
      odometer: {
        required: requiredIf(function (model) {
          return this.entity.orm !== null && this.entity.orm !== undefined
        }),
        validOdometer: (value) => value > 0 && value <= 9999999
      },
      claimNo: {
        required: requiredIf(function (model) {
          if (this.entity.audatex !== null && this.entity.audatex !== undefined) return true
          if (this.entity.orm !== null && this.entity.orm !== undefined) return true
        })
      },
      jobStart: {
        required: requiredIf(function (model) {
          return (this.entity.orm !== null && this.entity.orm !== undefined) ||
          this.entity.jobEnd
        })
        // validation added in booking area, this code was returning false as comparing iso time from db to local time out of the date picker
        // ,
       // ,
        // validJobStart: function(value, model) {
        //   return value <= this.entity.jobEnd
        // }
      },
      jobEnd: {
        required: requiredIf(function (model) {
          return (this.entity.orm !== null && this.entity.orm !== undefined) ||
          this.entity.jobStart
        }),
        validJobEnd: function(value, model) {
          return value >= this.entity.jobStart
        }
      },
      damageSeverity: {
        required: requiredIf(function (model) {
          return this.insurer && this.insurer.damageSeverity
        }),
        validDamageSeverity: function(value, model) {
          return value > 0 || !(this.insurer && this.insurer.damageSeverity)
        }
      }
    },
    vehicle: {
      rego: {
        required
      },
      customer: {
        required
      },
      model: {
        required
      },
      bodyId: {
        required
      },
      bodyId2: {
        required: requiredIf(function (model) {
          return this.entity.orm !== null && this.entity.orm !== undefined
        })
      },
      paintGroup: {
        required
      },
      vin: {
        customMinLength: customMinLength,
        maxLength: maxLength(17)
      },
      id: {
        required,
        validGuid: guidValidator
      },
      buildMonth: {
        required: requiredIf(function (model) {
          return this.entity.orm !== null && this.entity.orm !== undefined
        })
      },
      buildYear: {
        required: requiredIf(function (model) {
          return this.entity.orm !== null && this.entity.orm !== undefined
        })
      },
      colour: {
        required
      },
      transmissionType: {
        required
      }
    },
    customer: {
      companyName: {
        required: requiredIf(function (model) {
          return !model.isPerson
        })
      },
      firstname: {
        required: requiredIf(function (model) {
          return model.isPerson
        })
      },
      lastname: {
        required: requiredIf(function (model) {
          return model.isPerson
        })
      },
      emails: {
        $each: {
          address: {
            email
          }
        }
      }
    },
    insurer: {
      insurerId: {
        required,
        validGuid: guidValidator
      },
      name: {
        required
      },
      abn: {
        minLength: minLength(11),
        validAbn: abnValidator
      },
      arbn: {
        minLength: minLength(9)
      }
    },
    quoteCustomer: {
      active: {
        required,
        isActive: (value) => value === true
      }
    },
    quoteVehicle: {
      isActive: {
        required,
        isActive: (value) => value === true
      }
    }
  }
}
