<template>
  <div :class="[`quote-side-menu-${$user.info.theme}`]">
    <app-quote-side-menu :min-width="5"
      :fixed-menu="true"
      :subquotes="value.subQuoteNos"
      :currentsubquote="value.subQuoteNo"
      :current-route-name="$route.name"
      :is-new="value.isNew"
      :read-only-statuses="value.readOnlyStatuses"
      :is-synced="value.isSynced"
      @select-subquote="selectSubQuote"
      :collapsed.sync="collapsed">
      <aside class="menu"
        slot="side-menu">
        <!-- <div class="menu-label">
          <div class="columns is-gapless">
            <div class="column">
              <span class="title is-6"
                style="white-space: nowrap">
                Quote No: {{ entityName }}
              </span>
            </div>
            <div class="column">
              <span v-if="value.quotingMethod"
                class="tag is-pulled-right tooltip"
                :data-tooltip="`${quotingMethodDescription} quote`"
                :class="[value.quotingMethod === quotingMethodTypes.Hour ? 'is-info' : 'is-success']">{{ value.quotingMethod }}</span>
            </div>
          </div>
        </div> -->
        <ul class="menu-list is-icon-menu-list">
          <li>
            <router-link :to="{ name: routeTypes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              exact
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query })">
              <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded': $v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error || $v.insurer.$error || $v.insurer.$invalid}"
                :data-badge="$v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error? '' : null">
                <span v-if="!collapsed">
                  <span>Header</span>
                </span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon">
                    <i class="mdi mdi-account-edit mdi-24px" />
                  </span>
                  <span class="is-size-8">HEADER</span>
                </div>
              </span>
            </router-link>
          </li>

          <li v-if="value.audatex || value.pnet">
            <router-link :to="{ name: routeTypes.QuotePnetAudanet.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              exact
              @click.native.prevent="goToRoute({ name: routeTypes.QuotePnetAudanet.name, params: { quoteId : $route.params.quoteId }, query: $route.query })">
              <span>
                <span v-if="!collapsed">PNET{{ pnetAudanetMenuText }}</span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon">
                    <i class="mdi mdi-connection mdi-24px" />
                  </span>
                  <span class="is-size-8">PNET{{ pnetAudanetMenuText }}</span>
                </div>
              </span>
            </router-link>
          </li>

          <li v-if="value.orm">
            <router-link :to="{ name: routeTypes.QuoteOrm.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              exact
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteOrm.name, params: { quoteId : $route.params.quoteId }, query: $route.query })">
              <span>
                <span v-if="!collapsed">ORM</span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon">
                    <i class="mdi mdi-connection mdi-24px" />
                  </span>
                  <span class="is-size-8">ORM</span>
                </div>
              </span>
            </router-link>
          </li>

          <li v-if="value.arnieCount">
            <router-link :to="{ name: routeTypes.QuoteArnie.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              exact
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteArnie.name, params: { quoteId : $route.params.quoteId }, query: $route.query})">
              <span>
                <span v-if="
                  !collapsed">ARNIE</span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon">
                    <i class="mdi mdi-connection mdi-24px" />
                  </span>
                  <span class="is-size-8">ARNIE</span>
                </div>
              </span>
            </router-link>
          </li>

          <li v-if="!!ShowNewQuoteDetail">
            <router-link :to="{ name: routeTypes.QuoteSelections.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteSelections.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed">Quoting</span>
              <div v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon relative-position">
                  <i class="mdi mdi-car mdi-24px" />
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded absolute-position': $v.quotingGroup.$error || $v.quotingGroup.$invalid}"
                    :data-badge="$v.quotingGroup.$error || $v.quotingGroup.$invalid ? '' : null" />
                </span>
                <span class="is-size-8">QUOTING</span>
              </div>
            </router-link>
          </li>

          <li v-if="!ShowNewQuoteDetail">
            <router-link :to="{ name: routeTypes.QuoteAnnotations.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteAnnotations.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span v-if="!collapsed">Annotations</span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon">
                    <i class="mdi mdi-file-document-edit mdi-24px" />
                  </span>
                  <span class="is-size-8">ANNOTATIONS</span>
                </div>
              </span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: routeTypes.QuoteRatesMarkups.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteRatesMarkups.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed">
                <span>Rates &amp; Markups</span>
              </span>
              <span v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon">
                  <i class="mdi mdi-file-percent-outline mdi-24px" />
                </span>
                <span class="is-size-8">RATES</span>
              </span>
            </router-link>
          </li>
          <li v-if="!value.isAssessmentEnabled">
            <router-link :to="{ name: routeTypes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span v-if="!collapsed">
                  Invoice
                </span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon relative-position">
                    <i class="mdi mdi-file-document-arrow-right mdi-24px" />
                    <span v-if="invoice"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="[$v.entity.invoices.$error ? 'has-badge-danger has-badge-icon-exclamation' : (invoice.isNew ? 'has-badge-icon-check has-badge-gold' : 'has-badge-icon-check has-badge-success')]"
                      data-badge="" />
                  </span>
                  <span class="is-size-8">INVOICE</span>
                </div>
              </span>

            </router-link>
          </li>
          <li v-else>
            <router-link :to="{ name: routeTypes.QuoteAssessmentInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteAssessmentInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span v-if="!collapsed">
                  Invoice
                </span>
                <div v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon relative-position">
                    <i class="mdi mdi-file-document-arrow-right mdi-24px" />
                    <span v-if="invoice"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="[$v.entity.invoices.$error ? 'has-badge-danger has-badge-icon-exclamation' : (invoice.isNew ? 'has-badge-icon-check has-badge-gold' : 'has-badge-icon-check has-badge-success')]"
                      data-badge="" />
                  </span>
                  <span class="is-size-8">INVOICE</span>
                </div>
              </span>

            </router-link>
          </li>

          <li>
            <router-link :to="{ name: routeTypes.QuoteExcessInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              event=""
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteExcessInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span>
                <span v-if="!collapsed">
                  Excess Invoice
                </span>
                <div class="is-flex is-flex-direction-column is-align-items-center"
                  v-else>
                  <span class="icon relative-position">
                    <i class="mdi mdi-file-document-plus mdi-24px" />
                    <span v-if="excessInvoice"
                      class="has-badge-rounded has-badge-small has-badge-icon-check absolute-position"
                      :class="[excessInvoice.isNew ? 'has-badge-gold' : 'has-badge-success']"
                      data-badge="" />
                  </span>
                  <span class="is-size-8">EXCESS INV</span>
                </div>
              </span>

            </router-link>
          </li>

          <li v-if="!!ShowNewQuoteDetail">
            <router-link :to="{ name: routeTypes.QuoteBooking.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteBooking.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed"
                class="has-badge-child-offset3">
                <span v-show="!collapsed">Bookings</span>
              </span>
              <div v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon relative-position">
                  <i class="mdi mdi-calendar-month-outline mdi-24px" />
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded absolute-position': $v.ormSummaryGroup.$error || $v.estimateJobStartEnd.$invalid}"
                    :data-badge="$v.ormSummaryGroup.$error || $v.estimateJobStartEnd.$invalid ? '' : null" />
                </span>
                <span class="is-size-8">BOOKINGS</span>
              </div>
            </router-link>
          </li>

          <li v-if="!!ShowNewQuoteDetail">
            <router-link :to="{ name: routeTypes.QuotePartsControl.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuotePartsControl.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed">
                <span>
                  <span v-show="!collapsed">Parts</span>
                </span>
              </span>
              <span v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon">
                  <i class="mdi mdi-car-door mdi-24px" />
                </span>
                <span class="is-size-8">PARTS</span>
              </span>
            </router-link>
          </li>
          <li v-if="value.isAssessmentEnabled">
            <router-link :to="{ name: routeTypes.AuthoriseView.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.AuthoriseView.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed">
                <span v-show="!collapsed">Authorise</span>
              </span>
              <span v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon">
                  <i class="mdi mdi-playlist-check mdi-24px" />
                </span>
                <span class="is-size-8">AUTHORISE</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.QuoteItemsOverview.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              event=""
              active-class="is-active"
              :class="{ 'is-disabled': !enableItemRoutes || value.isNew}"
              @click.native.prevent="goToRoute({ name: routeTypes.QuoteItemsOverview.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span v-if="!collapsed">
                <span v-show="!collapsed">Overview</span>
              </span>
              <span v-else
                class="is-flex is-flex-direction-column is-align-items-center">
                <span class="icon">
                  <i class="mdi mdi-car mdi-24px" />
                </span>
                <span class="is-size-8">OVERVIEW</span>
              </span>
            </router-link>
          </li>
        </ul>
        <div v-if="!ShowNewQuoteDetail">
          <span class="menu-label mb-1">
            Items
          </span>
          <ul class="menu-list is-icon-menu-list icon-items">
            <li>
              <router-link :to="{ name: routeTypes.QuoteLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuoteLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span>
                  <span v-if="!collapsed"
                    class="has-badge-child-offset3">
                    <span v-show="!collapsed">Labour</span>
                  </span>
                  <div v-else
                    class="is-flex is-flex-direction-column is-align-items-center">
                    <span class="icon icon-item-responsive relative-position">
                      <i class="mdi mdi-car-wrench mdi-24px" />
                      <span v-show="labours.length"
                        class="has-badge-rounded has-badge-small absolute-position"
                        :class="getBadgeClass('labours')"
                        :data-badge="labours.length" />
                    </span>
                    <span class="is-size-8">LABOUR</span>
                  </div>
                </span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: routeTypes.QuoteOtherLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuoteOtherLabour.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span v-if="!collapsed">
                  <span>
                    <span>Other Labour</span>
                  </span>
                </span>
                <span v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon icon-item-responsive relative-position">
                    <i class="mdi mdi-hammer-wrench mdi-24px" />
                    <span v-show="others.length"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="getBadgeClass('others')"
                      :data-badge="others.length" />
                  </span>
                  <span class="is-size-8">OTHERS</span>
                </span>

              </router-link>
            </li>
            <li>
              <router-link :to="{ name: routeTypes.QuotePart.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuotePart.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span v-if="!collapsed">
                  <span class="icon-item-responsive">
                    <span v-show="!collapsed">Parts</span>
                  </span>
                </span>
                <span v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon icon-item-responsive relative-position">
                    <i class="mdi mdi-car-door mdi-24px" />
                    <span v-show="parts.length"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="getBadgeClass('parts')"
                      :data-badge="parts.length" />
                  </span>
                  <span class="is-size-8">PARTS</span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: routeTypes.QuoteOpg.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuoteOpg.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span v-if="!collapsed">
                  <span>
                    <span v-show="!collapsed">Other Paint Group</span>
                  </span>
                </span>
                <span v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon icon-item-responsive relative-position">
                    <i class="mdi mdi-spray mdi-24px" />
                    <span v-show="opgs.length"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="getBadgeClass('opgs')"
                      :data-badge="opgs.length" />
                  </span>
                  <span class="is-size-8">OPG</span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: routeTypes.QuoteMisc.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuoteMisc.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span v-if="!collapsed">
                  <span>
                    <span v-show="!collapsed">Misc</span>
                  </span>
                </span>
                <span v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon icon-item-responsive relative-position">
                    <i class="mdi mdi-car-coolant-level mdi-24px" />
                    <span v-show="miscs.length"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="getBadgeClass('miscs')"
                      :data-badge="miscs.length" />
                  </span>
                  <span class="is-size-8">MISC</span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: routeTypes.QuoteSublet.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                active-class="is-active"
                :class="{ 'is-disabled': !enableItemRoutes}"
                @click.native.prevent="goToRoute({ name: routeTypes.QuoteSublet.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                exact>
                <span v-if="!collapsed">
                  <span>
                    <span v-show="!collapsed">Sublet</span>
                  </span>
                </span>
                <span v-else
                  class="is-flex is-flex-direction-column is-align-items-center">
                  <span class="icon icon-item-responsive relative-position">
                    <i class="mdi mdi-car-windshield mdi-24px" />
                    <span v-show="sublets.length"
                      class="has-badge-rounded has-badge-small absolute-position"
                      :class="getBadgeClass('sublets')"
                      :data-badge="sublets.length" />
                  </span>
                  <span class="is-size-8">SUBLET</span>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </aside>
    </app-quote-side-menu>
  </div>
</template>

<script>
import AppQuoteSideMenu from '@/components/AppQuoteSideMenu'
import QuoteRoutes from './route-types'
import { QuotingMethodTypes } from '@/enums'
import Guid from '@/components/Guid'
import QuoteService from './QuoteService'
import { EventHubTypes, InvoiceTypes } from '@/enums'
import { SmsService } from '@/services'
import { QuoteItemDifferenceMixin } from './mixins'

export default {
  name: 'QuoteSideMenu',
  inject: ['$vv'],
  components: {
    AppQuoteSideMenu
  },
  mixins: [QuoteItemDifferenceMixin],
  props: {
    value: null,
    customer: null,
    vehicle: null,
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      resizeInterval: null,
      menuHeightOffset: '50vh',
      screenScrollHeight: '50vh',
      headerHeight: 0,
      imageCount: 0,
      documentCount: 0,
      unreadInterval: null,
      unreadCount: 0,
      collapsed: true
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return QuoteRoutes
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    quotingMethodDescription() {
      if (this.value.quotingMethod === this.quotingMethodTypes.Dollar) {
        return Object.getOwnPropertyNames(this.quotingMethodTypes)[0]
      } else if (this.value.quotingMethod === this.quotingMethodTypes.Hour) {
        return Object.getOwnPropertyNames(this.quotingMethodTypes)[1]
      }
    },
    enableItemRoutes() {
      return Guid.validGuid(this.value.insurerId) && Guid.validGuid(this.value.vehicleId) && !this.$v.quoteHeaderGroup.$error
    },
    isHeaderInValid() {
      return this.$v.quoteHeaderGroup.$invalid
    },
    pnetAudanetMenuText() {
      if (this.value.isAudaNet) {
        return this.value.audatex && this.value.audatex.taskType ? `${this.value.audatex.taskType.toUpperCase()}` : 'AUDANET'
      } else {
        return ''
      }
    },
    labours() {
      return this.value.labours.filter((i) => !i.deleted)
    },
    others() {
      return this.value.others.filter((i) => !i.deleted)
    },
    parts() {
      return this.value.parts.filter((i) => !i.deleted)
    },
    opgs() {
      return this.value.opgs.filter((i) => !i.deleted)
    },
    miscs() {
      return this.value.miscs.filter((i) => !i.deleted)
    },
    sublets() {
      return this.value.sublets.filter((i) => !i.deleted)
    },
    invoice() {
      return this.value.invoices.find((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
    },
    excessInvoice() {
      return this.value.invoices.find((i) => (i.invoiceType === InvoiceTypes.Excess || i.invoiceType === InvoiceTypes.OwnerContribution) && !i.deleted)
    },
    isCustomerVehicleDeleted() {
      return (
        this.$v.quoteCustomer.active.required &&
        this.$v.quoteVehicle.isActive.required &&
        this.$v.vehicleCustomerIsActiveGroup.$dirty &&
        this.$v.vehicleCustomerIsActiveGroup.$error
      )
    },
    ShowNewQuoteDetail() {
      return this.$company?.setting?.newQuoteDetail || false
    },
    remarkCount() {
      return this.value && this.value.remarks ? this.value.remarks.filter((r) => !r.isDeleted).length : 0
    }
  },
  created() {
    this.getImageCount()
    this.getDocumentCount()
    this.getUnreadCount()
    this.autoRefreshUnreadCount()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.ImageCountChanged, (count) => (this.imageCount = count))
    this.$eventHub.$on(EventHubTypes.DocumentCountChanged, (count) => (this.documentCount = count))

    this.$v.vehicleCustomerIsActiveGroup.$touch()
    this.calcMenuHeight()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.ImageCountChanged)
    this.$eventHub.$off(EventHubTypes.DocumentCountChanged)
    clearInterval(this.unreadInterval)
  },
  methods: {
    calcMenuHeight() {
      let itemHeights = [document.getElementById('main-nav-menu')?.offsetHeight, document.getElementById('detail-nav-menu')?.offsetHeight]

      let height = itemHeights.reduce((r, t) => (t ?? 0) + (r ?? 0), 0)

      this.menuHeightOffset = height + 'px'
    },
    getBadgeClass(type) {
      let classes = []

      let syncRequired = false
      for (var item of this[type]) {
        if (this.isLineDifferentFromLastCommunication(item)) {
          syncRequired = true
          break
        }
      }

      if (this.$v.entity[type].$error) {
        classes.push('has-badge-danger')
      } else if (syncRequired) {
        classes.push('has-badge-warning')
      } else {
        classes.push('has-badge-info')
      }
      return classes
    },
    selectSubQuote(subQuoteNo) {
      this.$emit('select-subquote', subQuoteNo)
    },
    goToRoute(route) {
      if (
        this.isHeaderInValid &&
        (route.name === QuoteRoutes.QuoteSelections.name ||
          route.name === QuoteRoutes.QuoteRatesMarkups.name ||
          route.name === QuoteRoutes.QuoteBooking.name ||
          route.name === QuoteRoutes.QuoteAnnotations.name ||
          route.name === QuoteRoutes.QuotePartsControl.name ||
          route.name === QuoteRoutes.QuoteAssessmentInvoice.name ||
          route.name === QuoteRoutes.QuoteInvoice.name ||
          route.name === QuoteRoutes.AuthoriseView.name ||
          route.name === QuoteRoutes.QuoteItemsOverview.name)
      ) {
        this.$toast.open({
          message: 'Please complete the header details before proceeding',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 5000
        })
        return
      }

      // TODO: refactor code in this section, does the header page need to be
      // completed and saved before proceeding to the other pages or just completed?

      //TODO: add parts control

      if (
        (this.enableItemRoutes && route.name !== QuoteRoutes.QuoteInvoice.name && route.name !== QuoteRoutes.QuoteExcessInvoice.name) ||
        (route.name === QuoteRoutes.QuoteImages.name && !this.value.isNew) ||
        (route.name === QuoteRoutes.QuoteInvoice.name && !this.value.isNew) ||
        (route.name === QuoteRoutes.QuoteExcessInvoice.name && !this.value.isNew)
      ) {
        this.$router.push(route)
      } else {
        this.$toast.open({
          message: 'Please complete the header details first and save the quote before proceeding.',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 5000
        })
      }
    },
    async getImageCount() {
      const imageIds = await QuoteService.getImageIds(this.value.quoteId)
      this.imageCount = imageIds.length
    },
    async getDocumentCount() {
      const attachments = await QuoteService.getQuoteAttachments(this.value.quoteId)
      let count = Object.values(attachments.data).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
      this.documentCount = count
    },
    async getUnreadCount() {
      this.unreadCount = await SmsService.getUnreadCount(this.value.quoteId)
    },
    autoRefreshUnreadCount() {
      this.unreadInterval = setInterval(async () => {
        this.getUnreadCount()
      }, 30000)
    },
    handleClickImageBreakOut() {
      const baseUri = process.env.VUE_APP_BASE_URI === '/' ? process.env.VUE_APP_BASE_URI : `${process.env.VUE_APP_BASE_URI}/`
      window.open(`${baseUri}quotes/${this.value.quoteId}/imagesbreakout?uninterruptedview=true`, 'Imageviewer', {
        width: 1600,
        height: 900,
        left: 0,
        top: 0,
        fullscreen: 'yes',
        title: 'Image Breakout'
      })
    },
    isErrorCurrentRoute(route = null) {
      const obj = this.$v
      switch (route) {
        case QuoteRoutes.QuoteSelections.name:
          return obj.entity.labours.$anyError || obj.entity.parts.$anyError || obj.entity.miscs.$anyError || obj.entity.sublets.$anyError
        case QuoteRoutes.QuoteHeader.name:
          return obj.quoteHeaderGroup.$anyError || obj.ormHeaderGroup.$anyError || obj.insurer.$anyError
        case QuoteRoutes.QuoteBooking.name:
          return obj.estimateJobStartEnd.$anyError
        case QuoteRoutes.QuoteInvoice.name:
          return obj.entity.invoices.$anyError
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

span.superscript {
  vertical-align: super;
  font-size: smaller;
}

span.has-badge-child {
  margin-right: 1em;
}

.has-badge-icon {
  &::after {
    font-family: 'Material Design Icons';
    content: '';
    //content: attr(data-badge);
    // content: attr(data-test);
  }
}

.has-badge-icon-check {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f012c';
    //content: attr(data-test);
  }
}

.has-badge-icon-exclamation {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f0205';
  }
}

.side-menu-slot {
  min-height: calc(100vh - 3.25rem);
}

@media screen and (max-height: 900px) {
  .icon-item-responsive {
    display: none;
  }
}
</style>

<style lang="scss">
@import '../../assets/style/themes/default/variables';

@keyframes head {
  from {
    content: '\F0D87';
  }
  to {
    content: url('../../assets/images/kabaddi-alt.svg');
  }
}

.mdi-kabaddi::before {
  animation: head 250ms linear infinite;
  animation-play-state: paused;
}

.mdi-kabaddi:hover::before {
  animation-play-state: running;
}

.new-menu,
#main-sub-nav-menu {
  a,
  .menu-item-function {
    color: white !important;
  }

  a.is-active {
    > div {
      background-color: $primary !important;
      border-radius: 10px;
    }
  }

  .menu-item-function.is-active {
    background-color: $primary !important;
    border-radius: 10px;
  }

  .mid-rounded {
    padding: 5px;
    padding-top: 8px;
    border: 1px solid white;
    border-radius: 50%;
  }
}

.relative-position {
  position: relative !important;
}
.absolute-position {
  position: absolute !important;
  top: 0px;
  right: -5px;
}
</style>