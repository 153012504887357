<template>
  <div v-if="value && customer && vehicle"
    style="height: 100%;"
    class="quote-communications"
    :class="{'quote-communications-embedded': embedded}">
    <div class="tile is-vertical">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile"
          style="height: 79vh">
          <div class="tabs is-boxed is-small">
            <ul class="dark">
              <li :class="{ 'is-active' : mode === enums.sms}">
                <a @click="mode = enums.sms">
                  <span>SMS</span>
                </a>
              </li>
              <li class="disabled"
                :class="{ 'is-active' : mode === enums.email}">
                <a @click="mode = enums.email">
                  <span>Email</span>
                </a>
              </li>
              <li :class="{ 'is-active' : mode === enums.annotation}">
                <a @click="mode = enums.annotation">
                  <span>Job Sheet Notes</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="p-0 m-0 pt-3 column-flex-container send-meesage-container"
            v-if="mode === enums.sms">
            <div class="p-0 row height-60"
              :class="{'is-half': !embedded, 'is-full': embedded}"
              v-show="mode === enums.sms">
              <div class="field">
                <label class="label has-text-light">Send To</label>
                <div class="control">
                  <the-mask v-model="smsDetails.mobile"
                    :mask="phoneNoMask"
                    :tokens="phoneNoTokens"
                    class="input input-dark"
                    placeholder="Mobile No."
                    v-focus-inserted />
                </div>
              </div>
              <div class="field">
                <label class="label has-text-light">Message</label>
                <div class="control is-expanded">
                  <multiselect v-model="selectedTemplate"
                    class="multiselect-dark"
                    track-by="message"
                    label="message"
                    placeholder="Template"
                    :options="templates"
                    :loading="isTemplateLoading"
                    :searchable="true"
                    :allow-empty="true"
                    :options-limit="templateFilter.pageSize"
                    @select="selectTemplate" />
                </div>
              </div>
              <div class="field mb-0">
                <div class="control">
                  <textarea v-model="smsDetails.message"
                    class="textarea textarea-dark"
                    rows="8"
                    maxlength="2000"
                    placeholder="Text message" />
                </div>
                <!-- <span class="help">{{ smsDetails.message.length }}/2000</span> -->
              </div>

              <div class="is-flex is-justify-content-space-between mt-5">
                <a class="button is-primary is-outlined"
                  @click="isSmsTemplateModalActive = true">
                  <span class="icon mr-1">
                    <i class="mdi mdi-pencil mdi-26px" />
                  </span>
                  <span>Edit Templates</span>
                </a>

                <div class="is-disabled">
                  <a class="button is-success is-outlined"
                    :class="!smsDetails.message || !smsDetails.mobile ? 'is-disabled' : ''"
                    @click="send()"
                    data-tooltip="Send SMS"
                    :disabled="!smsDetails.message || !smsDetails.mobile">
                    <span class="icon mr-1">
                      <i class="mdi  mdi-send mdi-20px" />
                    </span>
                    <span>Send</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="mt-3 p-0 row height-40 scrollable message-container"
              :class="{'is-half': !embedded, 'is-full': embedded}">
              <div v-for="(message) in messages"
                :key="message.id"
                class="is-flex pr-1 py-2"
                :class="{ 'is-justify-content-flex-end': message.isIncoming }">
                <transition mode="out-in"
                  name="custom-classes-transition"
                  :enter-active-class="`animate__animated animate__slideInRight`"
                  :leave-active-class="`animate__animated animate__slideInRight`">
                  <article class="message is-flex"
                    :class="[{'is-primary' : message.isOutgoing}, {'is-success message-reply': message.isIncoming}, {'unread-animation': !message.isRead}]">
                    <div class="message-body has-background-grey-darker py-1 px-2">
                      <span v-if="message.isIncoming"
                        class="help is-italic has-text-success">From: 0{{ message.sender }}</span>
                      <span v-if="message.isOutgoing && message.recipients"
                        class="help is-italic has-text-primary">To: {{ message.recipients }}</span>
                      <span class="message-sent has-text-light">{{ message.message }}</span>
                      <div class="is-flex help is-italic"
                        :class="{ 'is-justify-content-flex-end': message.isIncoming }">
                        <span :class="[ {'has-text-primary': message.isOutgoing}, {'has-text-success': message.isIncoming} ]">
                          {{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}
                        </span>
                      </div>
                    </div>
                  </article>
                </transition>
              </div>
            </div>
          </div>

          <div style="max-height: 100%; overflow-y: auto;  overflow-x: hidden;"
            v-if="mode === enums.annotation"
            :class="{'quote-annotations-embedded': embedded}">
            <div class="is-vertical is-half pr-1 mt-2">
              <div class="columns is-multiline">
                <div class="column"
                  :class="{'is-half': !embedded,'is-full':embedded}">
                  <div class="field">
                    <label class="label has-text-light">RR</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.RR).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">REP</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.REP).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">FIBER</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.FIBER).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">CD</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.CD).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">PART</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.PART).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">MISC</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.MISC).remarks"
                      :rows="rows" />
                  </div>
                </div>
                <div class="column"
                  :class="{'is-half': !embedded,'is-full':embedded}">
                  <div class="field">
                    <label class="label has-text-light">RWA</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.RWA).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">PAINT</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.PAINT).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">CRUSH</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.CRUSH).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">MECH</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.MECH).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">OPG</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.OPG).remarks"
                      :rows="rows" />
                  </div>
                  <div class="field">
                    <label class="label has-text-light">SUBLET</label>
                    <textarea class="textarea textarea-dark"
                      v-model="getAnnotation(remarkTypes.SUBL).remarks"
                      :rows="rows" />
                  </div>
                </div>
              </div>

            </div>
          </div>

        </article>
      </div>
    </div>
    <sms-template-modal v-if="isSmsTemplateModalActive"
      :active.sync="isSmsTemplateModalActive"
      @close="closeSmsTemplateModal" />
  </div>
</template>

<script>
import { SmsSendMessageModel } from '@/classes/viewmodels'
import Multiselect from 'vue-multiselect'
import { FocusInserted } from '@/components/directives'
import { SmsService } from '@/services'
import { PhoneTypes } from '@/enums'
import SmsTemplateModal from '@/components/Sms/SmsTemplateModal'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { RemarkTypes } from '@/enums'
import Guid from '@/components/Guid'

const enums = {
  sms: 'sms',
  email: 'email',
  annotation: 'annotation'
}

export default {
  name: 'QuoteCommunications',
  components: { Multiselect, SmsTemplateModal },
  directives: {
    FocusInserted
  },
  mixins: [],
  props: {
    value: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    },
    vehicle: {
      type: Object,
      default: null
    },
    embedded: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      mode: enums.sms,
      sendMode: false,
      phoneNoMask: Array(31).join('P'),
      phoneNoTokens: {
        P: {
          pattern: /[0-9, ()\-+]/
        }
      },
      smsDetails: new SmsSendMessageModel(),
      isTemplateLoading: false,
      templates: [],
      messages: [],
      selectedTemplate: '',
      templateFilter: {
        message: '',
        pageIndex: 1,
        pageSize: 999
      },
      isSmsTemplateModalActive: false,
      interval: null,
      innerValue: null,
      rows: 1
    }
  },
  computed: {
    enums() {
      return enums
    },
    remarkTypes() {
      return RemarkTypes
    }
  },
  created() {
    this.getSmsMessageDropdown()
    this.autoRefresh()
    this.getMessages()

    this.innerValue = this.value
  },
  mounted() {
    if (this.customer) {
      const mobile = this.customer.phones.find((p) => p.type === PhoneTypes.Mobile.toString())
      this.smsDetails.mobile = mobile ? mobile.no : ''
    }
  },
  updated() {
    const unreadCount = this.messages.reduce((acc, cur) => (!cur.isRead ? ++acc : acc), 0)
    if (this.$refs.messageContainer && unreadCount) {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    toggleSend(value) {
      this.sendMode = value
    },
    async getMessages() {
      this.messages = await SmsService.getMessages(this.value.quoteId)
      this.setMessagesAsRead()
    },
    async send() {
      this.$showSpinner('Sending...')
      const jobStart = new Date(this.value.jobStart).toLocaleDateString(this.$userInfo.locale)
      const jobEnd = new Date(this.value.jobEnd).toLocaleDateString(this.$userInfo.locale)

      this.smsDetails.quoteId = this.value.quoteId
      this.smsDetails.customer = this.customer.firstname ? this.customer.firstname : this.customer.fullname
      this.smsDetails.rego = this.vehicle.rego
      this.smsDetails.jobStart = jobStart
      this.smsDetails.jobEnd = jobEnd

      this.smsDetails.excess = this.value.excessWithGst
        ? this.value.excess + roundAwayFromZero((this.value.excess * this.value.gstRate) / 100)
        : this.value.excess
      try {
        await SmsService.sendSms(this.smsDetails)
        this.$notification.openNotificationWithType('success', 'Send SMS', 'Message Sent')
        this.smsDetails.message = ''
        this.selectedTemplate = ''
      } catch (e) {
        this.$notification.openMessageXhrError('Send SMS', e)
      } finally {
        this.getMessages()
        this.$hideSpinner()
      }
    },
    async getSmsMessageDropdown() {
      this.isTemplateLoading = true
      this.templates = await SmsService.getSmsMessageDropdownProto(this.templateFilter)
      if (this.templateFilter.message !== '') {
        this.selectedTemplate = this.templates.find((c) => c.message === this.templateFilter.message)
      }
      this.isTemplateLoading = false
    },
    selectTemplate(selected) {
      this.templateFilter.message = selected.message
      this.smsDetails.message = selected.message
    },
    async closeSmsTemplateModal() {
      this.isSmsTemplateModalActive = false
      await this.getSmsMessageDropdown()
    },
    autoRefresh() {
      this.interval = setInterval(async () => {
        await this.getMessages()
      }, 30000)
    },
    stopAutoRefresh() {
      clearInterval(this.interval)
    },
    setMessagesAsRead() {
      const unread = this.messages.filter((m) => !m.isRead)
      if (unread && unread.length > 0) {
        setTimeout(async () => {
          await SmsService.setMessagesAsRead(this.value.quoteId)
          this.messages.forEach((m) => (m.isRead = true))
        }, 10000)
      }
    },
    getAnnotation(remarkType) {
      let annotation = this.innerValue.annotations.find((r) => r.remarkTypeId === remarkType)
      if (!annotation) {
        annotation = {
          isAlert: false,
          isDeleted: false,
          isNew: true,
          remarkDateTime: new Date(),
          remarkId: Guid.newGuid(),
          remarkTypeId: remarkType,
          remarks: ''
        }
        this.innerValue.annotations.push(annotation)
      }
      return annotation
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
article.message {
  width: 100%;
  &.message-reply {
    justify-content: end;
    > .message-body {
      text-align: right;
      border-width: 0 4px 0 0;
    }
  }
}
.message-body {
  width: 100%;
}
.message-sent {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.container {
  max-height: calc(100vh - 18em);
  overflow-y: auto;
  scroll-behavior: smooth;
}
@keyframes highlight-unread {
  0% {
    filter: brightness(0.4);
    filter: contrast(0.4);
    -webkit-filter: brightness(0.4);
    -webkit-filter: contrast(0.4);
  }
  50% {
    filter: brightness(0.6);
    filter: contrast(0.6);
    -webkit-filter: brightness(0.6);
    -webkit-filter: contrast(0.6);
  }
  100% {
    filter: brightness(1);
    filter: contrast(1);
    -webkit-filter: brightness(1);
    -webkit-filter: contrast(1);
  }
}
.unread-animation {
  animation-name: highlight-unread;
  animation-duration: 10s;
  animation-iteration-count: 1;
  font-weight: bold;
}

.quote-annotations-embedded {
  .label {
    color: aliceblue;
  }
}
.send-meesage-container {
  min-height: 25rem;
}

.message-container {
  min-height: 10rem;
}
</style>